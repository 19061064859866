<template>
  <!-- 已完成已发货详情 -->
  <div v-loading="loading">
    <back />
    <div class='globle_border content'>
      <header>
        <h3>采购单详情</h3>
        <div class="info">
          <div>商超名称：<span>{{ shopName }}</span></div>
          <div>商超编号：<span>{{ shopNum }}</span></div>
          <div>手机号码：<span>{{ contactTel }}</span></div>
          <div>签收时间：<span>{{ signTime }}</span></div>
        </div>
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table height="480" :data="tableData" style="width: 100%">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }} <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <!-- <el-table-column prop="serialId" label="商品编号" show-overflow-tooltip min-width="100" align="center">
          </el-table-column> -->
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="categoryName" label="商品分类" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="brand" label="商品品牌" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="specs" label="商品规格" show-overflow-tooltip min-width="80" align="center">
          </el-table-column>
          <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <!-- <el-table-column prop="purchaseCount" label="订单数量" show-overflow-tooltip min-width="100" align="center">
          </el-table-column> -->
          <el-table-column prop="productBox" label="订单箱数" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <!-- <el-table-column prop="deliveryCount" label="销售数量" show-overflow-tooltip min-width="100" align="center">
          </el-table-column> -->
          <el-table-column prop="deliveryBox" label="销售箱数" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="supplyPrice" label="销售单价" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="totalAmount" label="销售总价(元)" show-overflow-tooltip min-width="110" align="center">
          </el-table-column>
          <el-table-column prop="expirationDay" label="商品保质期" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="factoryName" label="生产厂家" show-overflow-tooltip fixed="right" min-width="110"
            align="center">
          </el-table-column>
        </el-table>
        <div class="bottom">
          <div>金额总计：<span style="color: red;font-weight: bolder;font-size: 20px;">
              ￥{{ Number(totalPrice).toFixed(2) }}
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      loading: false,
      shopName: '',//商超名称
      shopNum: '',//商超编号
      contactTel: '',//手机号码
      signTime: '',//签收时间
      totalPrice: null,//总价
      // 表格数据
      tableData: [],
    }
  },
  created() {
    this.getRequest()
  },
  methods: {
    // 请求详情列表
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.sendOrderInfo, {
        params: {
          purchaseId: this.$route.query.purchaseId,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.shopName = result.shopName
          this.shopNum = result.shopNum
          this.contactTel = result.contactTel
          this.signTime = result.signTime
          this.totalPrice = result.totalPrice
          this.tableData = result.purchaseProductVoList
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
  },
}
</script>

<style scoped lang='scss'>
.content {
  header {
    text-align: center;

    .info {
      display: flex;
      margin: 20px 0;
      padding: 0 50px;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.tableBox {
  overflow: auto;


  :deep .el-table--group::after,
  .el-table--border::after,
  .el-table::before {
    content: '';
    position: absolute;
    background-color: #EBEEF5;
    opacity: 0;
  }

  :deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 6px;
    /*滚动条宽度*/
    height: 4px;
    /*滚动条高度*/
  }

  .bottom {
    display: flex;
    align-items: flex-end;
    color: #505554;
    justify-content: flex-end;
    padding: 10px 0;

    div {
      margin-right: 20px;

    }
  }
}
</style>
